<template lang="pug">
structure-h1(:title="$t('orders.title1')")
structure-card
  b {{ $t('orders.box1_title') }}
  p {{ $t('orders.box1_sub_title') }}
structure-card.p-0.overflow-hidden(v-if="listOrders.length > 0")
  table.w-full
    thead
      tr.text-center
        th {{ $t('orders.table_date') }}
        th {{ $t('orders.table_cuantity') }}
        th {{ $t('orders.table_state') }}
        th(class="w-1/4") {{ $t('globals.options') }}
    tbody.text-center
      tr(v-for="order in listOrders", :key="order._id")
        td {{ setDate(order.created_at) }}
        td {{ order.total_eans }}
        td(:class="getClassColor(order.status)") {{ setStatus(order.status) }}
        td.flex.justify-center.button-group
          div(
            @click="order.status == 3 && sendEmails(order._id)",
            :class="{ disabled: order.status != 3 }"
          ) {{ $t('globals.send_email') }}
          div(
            @click="order.status == 3 && downloadFile(order._id, 'csv')",
            :class="{ disabled: order.status != 3 }"
          ) CSV
          div(
            @click="order.status == 3 && downloadFile(order._id, 'xlsx')",
            :class="{ disabled: order.status != 3 }"
          ) XLSX
</template>
<script>
import userRepository from "@/repositories/userRepository";
export default {
  name: "orders",
  data: () => ({}),
  computed: {
    listOrders() {
      return this.$store.getters["orders/listOrders"];
    },
  },
  methods: {
    setDate: (d) => {
      const date = new Date(d);
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    getClassColor(s) {
      switch (s) {
        case 1:
          return "text-red-400";
        case 2:
          return "text-yellow-400";
        case 3:
          return "";
      }
    },
    setStatus(s) {
      switch (s) {
        case null:
        case 1:
          return this.$t("orders.stauts_not_payed");
        case 2:
          return this.$t("orders.stauts_process");
        case 3:
          return this.$t("orders.status_complet");
      }
    },
    downloadFile(order_id, type) {
      if (type == "xlsx") userRepository.downloadXLSX(order_id);
      if (type == "csv") userRepository.downloadCSV(order_id);
    },
    sendEmails(order_id) {
      userRepository
        .sendOrderByEmail(order_id)
        .then(() => {
          this.$toast.success(this.$t("orders.email_send"));
        })
        .catch(() => {
          this.$toast.error(this.$t("profile.response_bad"));
        });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>